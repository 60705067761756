.badge-public {
  color: #fff;
  background-color: #257945;
}

.badge-onlyreg {
  background-color: #deda5b;
}

.badge-private {
  color: #fff;
  background-color: #721015;
}
/*# sourceMappingURL=composition.ba8a5740.css.map */
