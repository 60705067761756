.badge-public {
    color: white;
    background-color: #257945;
}

.badge-onlyreg {    
    background-color: #deda5b;
}

.badge-private {
    color: white;
    background-color: #721015;
}